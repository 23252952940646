<template>
  <div class="w-full">
    <div class="relative">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
      >
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <vue-select
        v-model="search"
        :options="data"
        label-by="label"
        :close-on-select="closeOnSelect ? true : false"
        searchable
        :loading="isLoading"
        clear-on-select
        :multiple="false"
        :placeholder="selectedCustomer ? selectedCustomer : placeholder"
        @search:input="handleSearchInput"
        :maxHeight="maxHeight"
        class="text-left block w-1/3 bg-white border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 encom_primary_border_focus sm:text-sm z-20"
      >
      </vue-select>
    </div>
  </div>
</template>

<script>
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { SearchIcon } from "@heroicons/vue/outline";

export default {
  props: [
    "placeholder",
    "selectedCustomer",
    "searchMethod",
    "data",
    "closeOnSelect",
    "isMultiple",
    "selectAllElements",
    "selectedAllElements",
    "clearAllElements",
    "elementToRemove",
    "maxHeight",
    "groupData",
    "extensionListConcat",
    "selectedElement",
  ],
  components: {
    VueSelect,
    SearchIcon,
  },
  data() {
    return {
      search: "",
      searchableTerm: "",
      isLoading: false,
    };
  },
  methods: {
    handleSearchInput(event) {
      this.searchableTerm = event.target.value;
    },
  },
  watch: {
    searchableTerm: function(val) {
      if (this.searchMethod) {
        if (val.length > 2) {
          this.searchMethod(val);
        }
      }
    },
    search: function(val) {
      this.isLoading = false;
      this.$emit("action", val.value);
    },
  },
};
</script>

<style></style>
