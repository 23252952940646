<template>
 <transition
  enter-active-class="transition ease-out duration-100"
  enter-from-class="opacity-0 -translate-y-1"
  enter-to-class="opacity-100 translate-y-0"
  leave-active-class="transition ease-in duration-150"
  leave-from-class="opacity-100 translate-y-0"
  leave-to-class="opacity-0 -translate-y-1">
  <div>
   <div class="z-10 inset-x-0 transform shadow-lg bg-white">
    <div class="max-w-auto mx-auto">
     <form
      class="space-y-8 divide-y divide-gray-200 p-5"
      @submit.prevent="onSubmit()"
      method="POST">
      <div class="space-y-8 divide-y divide-gray-200">
       <div>
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
         <div class="sm:col-span-4">
          <label for="email" class="block text-sm font-medium text-gray-700">
           {{ $t("ticket.subject") }}
          </label>
          <div class="mt-1">
           <input
            id="subject"
            v-model="form.subject"
            name="subject"
            type="text"
            required=""
            class="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm" />
          </div>
         </div>
         <div v-if="ticketsCategories.length > 0" class="sm:col-span-3">
          <label for="category" class="block text-sm font-medium text-gray-700">
           {{ $t("ticket.category") }}
          </label>
          <select
           @change="getCategoryId($event)"
           id="country"
           name="country"
           autocomplete="country"
           class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded sm:text-sm">
           <option value="">{{ $t("selectCategory") }}</option>
           <option
            v-for="category in ticketsCategories"
            :key="category.name"
            :value="category.id">
            {{ category.name }}
           </option>
          </select>
         </div>

         <div class="sm:col-span-6" v-if="categoryId">
          <label
           for="subCategory"
           class="block text-sm font-medium text-gray-700">
           {{ $t("ticket.subCategory") }}
          </label>
          <fieldset class="mt-1">
           <legend class="sr-only">
            {{ $t("ticket.subCategory") }}
           </legend>
           <div
            class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
            <div
             v-for="subCategory in matchingSubCategories"
             :key="subCategory.id"
             class="flex items-center">
             <input
              v-model="form.ticket_sub_category_id"
              :id="subCategory.id"
              :name="subCategory.id"
              :value="subCategory.id"
              type="radio"
              class="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500" />
             <label
              :for="subCategory.id"
              class="ml-3 block text-sm font-medium text-gray-700">
              {{ subCategory.name }}
             </label>
            </div>
           </div>
          </fieldset>
         </div>

         <div class="sm:col-span-6 space-y-8">
          <div class="grid grid-cols-1 gap-2 sm:grid-cols-6">
           <div class="sm:col-span-8 rounded-xl">
            <div class="relative" id="app">
             <div class="absolute pt-2 pr-3 z-10 right-0">
              <label
               for="file-upload"
               class="relative flex items-center gap-1 text-sm font-medium underline rounded-md cursor-pointer hover:text-gray-800">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-4 h-4">
                <path
                 fill-rule="evenodd"
                 d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                 clip-rule="evenodd" />
               </svg>
               <span> {{ $t("uploadAFile") }} </span>
               <input
                id="file-upload"
                name="file-upload"
                type="file"
                ref="file"
                class="sr-only"
                accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="addReplyDocument($event)" />
              </label>
             </div>
             <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"></ckeditor>
            </div>
            <div
             class="bg-white border-b border-l border-r border-gray-300"
             v-if="replyDocuments.length > 0">
             <ul role="list" class="divide-y divide-gray-300">
              <li
               v-for="(attachment, idx) in replyDocuments"
               :key="attachment.id"
               class="flex items-center justify-between py-1 pl-2 pr-4 text-sm">
               <div class="flex items-center flex-1 w-0">
                <PaperClipIcon
                 class="flex-shrink-0 w-5 h-5 text-gray-400 mr-1"
                 aria-hidden="true" />
                {{ attachment.name }}
               </div>
               <div class="flex items-center flex-shrink-0 gap-1 ml-4 text-sm">
                <a
                 @click="replyDocuments.splice(idx, 1)"
                 class="font-medium text-red-600 cursor-pointer hover:text-red-500"
                 :title="this.$t('delete')"
                 ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   stroke-width="2"
                   d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg
                ></a>
               </div>
              </li>
             </ul>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="pt-5">
       <div class="flex justify-end">
        <button
         type="button"
         @click="this.$emit('closeForm')"
         class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
         {{ $t("ticket.cancel") }}
        </button>
        <button
         type="submit"
         :disabled="editorData.trim() === '' || form.subject.trim() === ''"
         :class="{
          'body__table--header opacity-50 cursor-default':
           editorData.trim() === '' || form.subject.trim() === '',
          'body__table--header':
           editorData.trim() !== '' || form.subject.trim() !== '',
          'rounded inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white': true,
         }"
         class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white">
         {{ $t("ticket.send") }}
        </button>
       </div>
      </div>
     </form>
    </div>
   </div>
  </div>
 </transition>

 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import axios from "axios";
import {
 SortAscendingIcon,
 ChevronDownIcon,
 SearchIcon,
 PaperClipIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import QuickSearchConcerns from "../components/QuickSearchConcerns.vue";

import TicketTable from "../components/TicketTable.vue";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";

import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import ListStylePlugin from "@ckeditor/ckeditor5-list/src/liststyle";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

export default {
 name: "OpenTicket",
 props: ["closeFormButton", "checkStatus"],
 emits: ["close"],
 components: {
  SearchIcon,
  ChevronDownIcon,
  SortAscendingIcon,
  Popover,
  PopoverButton,
  PopoverPanel,
  TicketTable,
  ComfirmationDialog,
  QuickSearchConcerns,
  PaperClipIcon,
 },
 data() {
  return {
   editor: ClassicEditor,
   editorData: "",
   editorConfig: {
    fillEmptyBlocks: false,
    tabSpaces: 0,
    placeholder: "",
    plugins: [
     EssentialsPlugin,
     BoldPlugin,
     ItalicPlugin,
     LinkPlugin,
     ParagraphPlugin,
     ImageInsertPlugin,
     SimpleUploadAdapter,
     ImagePlugin,
     ImageResizePlugin,
     ImageStylePlugin,
     ImageToolbarPlugin,
     AutoImagePlugin,
     IndentPlugin,
     IndentBlockPlugin,
     ListStylePlugin,
    ],
    simpleUpload: {
     // The URL that the images are uploaded to.
     uploadUrl: `${this.$cookie.getCookie("API")}/api/v1/pictures`,

     // Enable the XMLHttpRequest.withCredentials property.
     withCredentials: false,

     // Headers sent along with the XMLHttpRequest to the upload server.
     headers: {
      "X-CSRF-TOKEN": "CSRF-Token",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    },
    toolbar: {
     items: [
      "heading",
      "bold",
      "italic",
      "|",
      "link",
      "|",
      "undo",
      "redo",
      "|",
      "indent",
      "outdent",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertImage",
     ],
    },
    indentBlock: {
     offset: 1,
     unit: "em",
    },
    language: "en",
    image: {
     toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
     ],
    },
    licenseKey: "",
   },
   form: {
    subject: "",
    body: "",
    customerAccount: account,
    ticket_sub_category_id: null,
    ticket_category_id: null,
    priority_id: null,
    tags: "",
   },
   account,
   ticketsCategories: [],
   categoryId: null,
   subCategories: [],
   matchingSubCategories: [],
   closeForm: true,
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   searchConcern: "",
   concerns: [],
   replyDocuments: [],
  };
 },
 methods: {
  async onSubmit(msg) {
   if (this.form.ticket_sub_category_id) {
    this.subCategories.map(sub => {
     if (sub.id == this.form.ticket_sub_category_id) {
      this.form.priority_id = sub.priority_id;
     }
    });
   } else {
    this.form.priority_id = 2;
   }
   let str = String(this.editorData);
   let noSpace = str.replaceAll("&nbsp;", "");

   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/tickets`,
    params: {
     customerAccount: this.account,
     subject: this.form.subject,
     body: noSpace,
     priority_id: this.form.priority_id,
     ticket_category_id: this.form.ticket_category_id,
     ticket_sub_category_id: this.form.ticket_sub_category_id,
    },
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then(res => {
     let id = res.data.id;
     for (let index = 0; index < this.replyDocuments.length; index++) {
      const element = this.replyDocuments[index];
      this.postDocument(id, element);
     }
     this.form.subject = "";
     this.form.body = "";
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
     this.$emit("closeForm");
    })
    .catch(err => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },

  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  async getTicketCategories() {
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/ticketCategories`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   this.ticketsCategories = res.data;
  },
  getCategoryId(event) {
   this.categoryId = event.target.value;
   this.form.ticket_category_id = parseInt(event.target.value);
   this.checkSubCategories(event.target.value);
  },
  async getSubCategories() {
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/ticketSubCategories`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   this.subCategories = res.data;
  },
  checkSubCategories(id) {
   this.matchingSubCategories = [];
   for (let index = 0; index < this.subCategories.length; index++) {
    if (this.subCategories[index].ticket_category_id == id) {
     this.matchingSubCategories.push(this.subCategories[index]);
    }
   }
  },
  async getConcerns(term) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/customerConcerns/search?customerAccount=${
      this.account
     }&term=${term}`
    );
    this.concerns = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  putTag(event) {
   console.log(event);
   if (this.form.tags.length > 0) {
    this.form.tags = this.form.tags + ", " + event.value;
   } else {
    this.form.tags = event.value;
   }
   this.searchConcern = "";
  },
  addReplyDocument() {
   this.replyDocuments.push(this.$refs.file.files[0]);
  },
  postDocument(id, file) {
   let url = `${this.$cookie.getCookie(
    "API"
   )}/api/v1/attachments?customerAccount=${this.account}`;
   let formData = new FormData();
   formData.append("file", file);
   formData.append("attachable_id", id);
   formData.append("attachable_type", "App\\\Ticket");
   axios
    .post(url, formData)
    .then(res => {
     const successMsg = this.$t("elementCreatedSuccessfully", {
      element: this.$t("ticketTable.subject"),
     });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
    })
    .finally(() => {
     this.$emit("updateTicketDetail", id);
    })
    .catch(error => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
 },
 mounted() {
  this.getTicketCategories();
  this.getSubCategories();
 },
 computed: {
  ...mapGetters(["language"]),
 },
 watch: {
  searchConcern: function (val) {
   if (val.length > 2) {
    this.getConcerns(val);
   }
  },
 },
};
</script>

<style>
.ck {
 height: 20vh;
}
</style>
