<template>
 <div class="flex min-h-full">
  <!-- Static sidebar for desktop -->

  <div class="flex w-0 flex-1 flex-col grid grid-cols-12">
   <aside
    v-show="isListShow"
    class="h-full col-span-3 hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 border-r mr-1"
   >
    <div
     class="bg-gray-100 px-4 py-2 border-b border-gray-200 sm:px-6 grid flex justify-between inline-flex"
    >
     <div class="-ml-8 -mt-4 row-start-1">
      <div class="ml-4 mt-8 inline-flex">
       <div class="inline-flex">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
         <span class="ticket_subject">{{ ticket.subject }} [#{{ ticket.id }}]</span>
        </h3>
       </div>
       <br />
      </div>
     </div>
    </div>
    <div class="p-2 ml-1 mr-1 flex inline-flex justify-between">
     <div class="w-3/4 mt-2 flex rounded shadow-sm">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
       <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
       </div>
       <input
        type="text"
        name="email"
        id="email"
        class="focus:encom_border_input block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
        :placeholder="[isFocus ? $t('header.searchTicketPlaceHolder') : $t('header.search')]"
        v-model="this.search"
        @focus="isFocus = true"
        @blur="isFocus = false"
       />
      </div>
      <Menu as="div" class="relative inline-block text-left">
       <MenuButton
        type="button"
        class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100"
       >
        <FilterIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
       </MenuButton>
       <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
       >
        <MenuItems
         class="z-10 origin-top-left absolute mt-2 w-60 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
         <div class="py-1">
          <MenuItem v-for="status in statusList" :key="status.id" v-slot="{ active }">
           <a
            :class="[
             status.current ? 'font-medium text-gray-900' : 'text-gray-500',
             active ? 'bg-gray-100' : '',
             'block px-4 py-2 text-sm cursor-pointer mx-1 rounded',
            ]"
            @click="this.pushSearchStatus(status.name)"
           >
            <div class="flex justify-between">
             {{ status.name }}
             <svg
              v-show="this.searchStatus.includes(status.name)"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
             >
              <path
               fill-rule="evenodd"
               d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
               clip-rule="evenodd"
              />
             </svg>
            </div>
           </a>
          </MenuItem>
          <MenuItem class="">
           <a
            @click="showClosedTickets()"
            :class="[
             searchStatus.includes('closed') ? 'font-medium text-gray-900' : 'text-gray-500',
             active ? 'bg-gray-100' : '',
             'block px-4 py-2 text-sm cursor-pointer mx-1 rounded',
            ]"
           >
            <div class="flex justify-between">
             {{ $t("closed") }}
             <svg
              v-show="this.searchStatus.includes('closed')"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
             >
              <path
               fill-rule="evenodd"
               d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
               clip-rule="evenodd"
              />
             </svg>
            </div>
           </a>
          </MenuItem>
         </div>
        </MenuItems>
       </transition>
      </Menu>
     </div>
     <div class="mt-3 row-start-1">
      <button
       @click="this.isListShow = false"
       title="Hide tickets list"
       class="rounded inline-flex justify-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-medium bg-white text-gray-700 hover:bg-gray-50"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
       >
        <path
         fill-rule="evenodd"
         d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
         clip-rule="evenodd"
        />
       </svg>
      </button>
     </div>
    </div>
    <!-- Start secondary column (hidden on smaller screens) -->
    <div class="mx-1">
     <TicketListDetail
      :ticketId="this.id"
      :search="this.search"
      :searchStatus="this.searchStatus"
      @updateTicketDetail="(...args) => this.$router.push(`/ticket/${args[0]}`)"
     />
    </div>

    <!-- End secondary column -->
   </aside>

   <main :class="[isListShow ? 'col-span-9' : 'col-span-12', 'flex w-full flex-auto flex-col']">
    <div class="py-4 w-full">
     <div class="max-w-3xl px-1 xl:grid xl:max-w-max xl:grid-cols-8">
      <div class="xl:col-span-5 xl:border-r xl:border-gray-200 xl:pr-3">
       <div class="md:flex md:items-center md:justify-start md:space-x-4 xl:border-b xl:pb-2">
        <div class="flex space-x-2">
         <button
          v-show="!isListShow"
          @click="this.isListShow = true"
          title="Hide tickets list"
          class="rounded inline-flex justify-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-medium bg-white text-gray-700 hover:bg-gray-50"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           viewBox="0 0 20 20"
           fill="currentColor"
          >
           <path
            fill-rule="evenodd"
            d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
            clip-rule="evenodd"
           />
          </svg>
         </button>
         <h1 class="text-2xl font-bold text-gray-900">
          {{ ticket.subject }}
         </h1>

         <!--  <p class="mt-2 text-sm text-gray-500">
            #{{ $t("ticketOpenedBy", { id: ticket.id }) }}

            {{ " " }}
            <a href="#" class="font-medium text-gray-900">Hilary Mahy</a>
            {{ " " }}
            in
            {{ " " }}
            <a href="#" class="font-medium text-gray-900">Customer Portal</a>
           </p> -->
        </div>
        <div v-show="ticket.open">
         <span
          :class="{
           encom_ouvert_ticket_new: ticket.status_id == 1,
           encom_en_traitement_ticket_new: ticket.status_id == 2,
           encom_en_attente_new: ticket.status_id == 3,
           encom_autre_ticket_new: ticket.status_id == 4,
          }"
          class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
         >
          {{ checkStatus(ticket.status_id) }}
         </span>
         <!--   <span
          :class="[
           ticket.status_id == 1
            ? 'encom_ouvert_ticket inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold text-white'
            : ticket.status_id == 2
            ? 'encom_en_traitement_ticket inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold text-white'
            : ticket.status_id == 3
            ? 'encom_pink_bg inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold text-white'
            : 'encom_closed_ticket inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold text-white',
          ]"
         >
          {{ checkStatus(ticket.status_id) }}
         </span> -->
        </div>
       </div>
       <aside class="mt-8 xl:hidden">
        <h2 class="sr-only">Details</h2>
        <div class="space-y-5">
         <div class="flex items-center space-x-2">
          <font-awesome-icon
           :icon="ticket.open === 1 ? 'lock-open' : 'lock'"
           :class="[ticket.open === 1 ? 'text-green-500 ' : 'text-red-500', 'self-center h-4 w-4']"
          />
          <span
           :class="[ticket.open === 1 ? 'text-green-600 ' : 'text-red-600', 'text-sm font-medium']"
          >
           {{ ticket.open ? $t("ticketOpen") : $t("ticketClosed") }}
          </span>
         </div>
         <div class="flex items-center space-x-2">
          <font-awesome-icon icon="comment-dots" class="h-4 w-4 text-gray-400" />
          <span class="text-sm font-medium text-gray-900">
           {{ $t("ticketReplies", { numb: ticketReplies.length }) }}
          </span>
         </div>
         <div class="flex items-center space-x-2">
          <font-awesome-icon icon="calendar" class="h-4 w-4 text-gray-400" />
          <span v-if="ticket.created_at" class="text-sm font-medium text-gray-900">
           {{ $t("createdAt") }}
           <time :datetime="ticket.created_at" class="capitalize">
            {{ $d(ticket.created_at, "longNumb") }}</time
           >
          </span>
         </div>
        </div>

        <!--  <div class="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
           <div>
            <h2 class="text-sm font-medium text-gray-500">Assignees</h2>
            <ul role="list" class="mt-3 space-y-3">
             <li class="flex justify-start">
              <a href="#" class="flex items-center space-x-3">
                 <div class="flex-shrink-0">
                <img
                 class="h-5 w-5 rounded-full"
                 src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                 alt=""
                />
               </div> 
               <div class="text-sm font-medium text-gray-900">{{ ticket.assignee_id }}</div>
              </a>
             </li>
            </ul>
           </div>
           <div>
            <h2 class="text-sm font-medium text-gray-500">Tags</h2>
            <ul role="list" class="mt-2 leading-8">
             <li class="inline">
              <a
               href="#"
               class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
              >
               <div class="absolute flex flex-shrink-0 items-center justify-center">
                <span class="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
               </div>
               <div class="ml-3.5 text-sm font-medium text-gray-900">Bug</div>
              </a>
              {{ " " }}
             </li>
             <li class="inline">
              <a
               href="#"
               class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
              >
               <div class="absolute flex flex-shrink-0 items-center justify-center">
                <span class="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
               </div>
               <div class="ml-3.5 text-sm font-medium text-gray-900">Accessibility</div>
              </a>
              {{ " " }}
             </li>
            </ul>
           </div>
          </div> -->
       </aside>
       <div class="py-3 xl:pt-6 xl:pb-0">
        <h2 class="sr-only">Description</h2>
        <div class="prose max-w-none">
         <p v-html="ticket.body" class="ticketBody"></p>
        </div>
       </div>

       <section aria-labelledby="activity-title" class="mt-8 xl:mt-10">
        <div>
         <div class="divide-y divide-gray-200">
          <div class="pb-4">
           <h2 id="activity-title" class="text-lg font-medium text-gray-900">
            {{ $t("activityFeed") }}
           </h2>
          </div>
          <div class="pt-6">
           <!-- Activity feed-->

           <div v-if="ticketReplies.length > 0" class="flow-root">
            <div v-for="reply in ticketReplies" :key="reply.id">
             <div class="grid grid-col-10 px-2 py-2 flex justify-between">
              <div class="bubbleWrapper xl:pl-10">
               <div v-if="reply.origin === 'admin'" class="inlineContainer other">
                <div>
                 <div class="other flex justify-between mt-2 mx-5" v-if="reply.origin === 'admin'">
                  <p class="text-gray-500 text-xs text-left mr-2 capitalize">
                   {{ $d(reply.created_at, "longText") }}
                  </p>
                  <p class="text-gray-500 text-xs text-left">
                   {{ reply.id ? getUserReply(reply.id) : "" }}
                  </p>
                 </div>
                 <div
                  class="other py-5 sm:p-6 text-sm break-wordsatic"
                  :class="{
                   internalNote: !reply.customerVisible,
                   otherBubble: reply.customerVisible,
                  }"
                 >
                  <p>
                   <span v-html="reply.body"></span>
                  </p>

                  <div
                   v-if="reply.attachments && reply.attachments.length > 0"
                   class="hidden sm:block"
                   aria-hidden="true"
                  >
                   <div class="py-2">
                    <div class="border-t border-gray-200" />
                   </div>
                  </div>
                  <div
                   v-if="reply.attachments && reply.attachments.length > 0"
                   class="px-2 py-1 text-xs"
                  >
                   <ul>
                    <li v-for="document in reply.attachments" :key="document.id">
                     <span
                      @click="openFile(document.id, document.mimeType)"
                      class="underline hover:text-gray-800 cursor-pointer flex justify-end gap-1"
                     >
                      {{ document.filename }}
                      <div class="flex gap-1">
                       <Loader v-if="loadDocument" />
                      </div>
                     </span>
                    </li>
                   </ul>
                  </div>
                 </div>
                 <div class="flex justify-end mr-4 -mt-1" v-if="reply.customerVisible === 0">
                  <p class="text-gray-500 text-xs italic font-semibold">
                   {{ $t("internalReplyContent") }}
                  </p>
                 </div>
                </div>
               </div>
              </div>
              <div class="bubbleWrapper col-end-10">
               <div
                v-if="reply.origin !== 'admin'"
                class="inlineContainer text-left w-60 lg:w-auto lg:max-w-2xl"
               >
                <div>
                 <div class="own flex justify-between mt-2 mx-5">
                  <p class="text-gray-500 text-xs text-left mr-2 capitalize">
                   {{ $d(reply.created_at, "longText") }}
                  </p>
                  <p class="text-gray-500 text-xs text-left">
                   {{ reply.id ? getUserReply(reply.id) : "" }}
                  </p>
                 </div>
                 <div class="ownBubble own px-4 py-5 sm:p-6 text-sm break-words -mt-5">
                  <p>
                   <span v-html="reply.body"></span>
                   <span v-if="reply.attachments.length > 0">
                    {{ reply.attachments }}
                   </span>
                  </p>
                 </div>
                </div>
               </div>
              </div>
             </div>
            </div>
           </div>

           <div v-else class="text-center">
            <font-awesome-icon icon="comment" class="h-10 w-10 text-gray-400" aria-hidden="true" />

            <p class="mt-1 text-sm text-gray-500">{{ $t("noMessage") }}.</p>
           </div>
           <div class="mt-6">
            <div class="bg-gray-100 overflow-hidden border-b">
             <form
              v-if="
               userPermissions.permissions.includes('ticketing.*') ||
               userPermissions.permissions.includes('ticketing.edit') ||
               (userPermissions.permissions.includes('ticketing.edit-own') && ticket.is_opener)
              "
              class="space-y-2"
              @submit.prevent="reply($t('ticketTable.successMsg'))"
              method="POST"
             >
              <div class="space-y-8 divide-y divide-gray-200">
               <div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                 <div class="sm:col-span-6">
                  <div id="app">
                   <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                   ></ckeditor>
                  </div>
                 </div>
                </div>
               </div>
              </div>

              <div class="mt-6 pb-5 flex items-center justify-end space-x-4">
               <button
                type="button"
                @click="openOrCloseTicket(ticket.open, ticket.id)"
                class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
               >
                <span v-if="ticket.open == 1" class="flex space-x-2">
                 {{ $t("closeTicket") }}
                </span>

                <span v-else class="flex space-x-2">
                 {{ $t("reopenTicket") }}
                </span>
               </button>
               <button
                type="submit"
                class="rounded inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white"
               >
                {{ $t("ticket.send") }}
               </button>
              </div>
             </form>
            </div>
           </div>
          </div>
         </div>
        </div>
       </section>
      </div>

      <aside class="hidden xl:block xl:pl-3 xl:col-span-2">
       <h2 class="sr-only">Details</h2>
       <div class="space-y-5">
        <div class="flex items-center space-x-2">
         <font-awesome-icon
          :icon="ticket.open === 1 ? 'lock-open' : 'lock'"
          :class="[ticket.open === 1 ? 'text-green-500 ' : 'text-red-500', 'self-center h-4 w-4']"
         />
         <span
          :class="[ticket.open === 1 ? 'text-green-600 ' : 'text-red-600', 'text-sm font-medium']"
         >
          {{ ticket.open ? $t("ticketOpen") : $t("ticketClosed") }}
         </span>
        </div>
        <div class="flex items-center space-x-2">
         <font-awesome-icon icon="comment-dots" class="h-4 w-4 text-gray-400" />
         <span class="text-sm font-medium text-gray-900">
          {{ $t("ticketReplies", { numb: ticketReplies.length }) }}
         </span>
        </div>
        <div class="flex items-center space-x-2">
         <font-awesome-icon icon="calendar" class="h-4 w-4 text-gray-400" />
         <span v-if="ticket.created_at" class="text-sm font-medium text-gray-900">
          {{ $t("createdAt") }}
          <time :datetime="ticket.created_at" class="capitalize">
           {{ $d(ticket.created_at, "longNumb") }}
          </time>
         </span>
        </div>
        <div class="flex items-center space-x-2">
         <font-awesome-icon icon="pen" class="h-4 w-4 text-gray-400" />

         <span v-if="ticket.updated_at" class="text-sm font-medium text-gray-900">
          {{ $t("lastModification") }}
          <time :datetime="ticket.updated_at" class="capitalize">
           {{ $d(ticket.updated_at, "longNumb") }}
          </time>
         </span>
        </div>
       </div>
       <div class="mt-6 space-y-8 border-t border-gray-200 py-6">
        <!-- <div>
         <h2 class="text-sm font-medium text-gray-500">Assignees</h2>
         <ul role="list" class="mt-3 space-y-3">
          <li class="flex justify-start">
           <a href="#" class="flex items-center space-x-3">
             <div class="flex-shrink-0">
             <img
              class="h-5 w-5 rounded-full"
              src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
              alt=""
             />
            </div> 
            <div class="text-sm font-medium text-gray-900">{{ ticket.assignee_id }}</div>
           </a>
          </li>
         </ul>
        </div>
        <div>
         <h2 class="text-sm font-medium text-gray-500">Tags</h2>
         <ul role="list" class="mt-2 leading-8">
          <li class="inline">
           <a
            href="#"
            class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
           >
            <div class="absolute flex flex-shrink-0 items-center justify-center">
             <span class="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
            </div>
            <div class="ml-3.5 text-sm font-medium text-gray-900">Bug</div>
           </a>
           {{ " " }}
          </li>
          <li class="inline">
           <a
            href="#"
            class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
           >
            <div class="absolute flex flex-shrink-0 items-center justify-center">
             <span class="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
            </div>
            <div class="ml-3.5 text-sm font-medium text-gray-900">Accessibility</div>
           </a>
           {{ " " }}
          </li>
         </ul>
        </div> -->
       </div>
      </aside>
     </div>
    </div>
   </main>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import TicketListDetail from "../components/TicketListDetail.vue";
import SearchFilter from "../components/SearchFilter.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";

import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import ListStylePlugin from "@ckeditor/ckeditor5-list/src/liststyle";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Dialog,
 DialogPanel,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import {
 FilterIcon,
 SearchIcon,
 BellIcon,
 CalendarIcon,
 ChatBubbleLeftEllipsisIcon,
 CheckCircleIcon,
 LockOpenIcon,
 MagnifyingGlassIcon,
 PencilIcon,
 TagIcon,
 UserCircleIcon as UserCircleIconMini,
} from "@heroicons/vue/solid";
import TicketsHeader from "./TicketsHeader.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "TicketDetail",
 props: {
  ticketId: {
   type: String,
   required: true,
  },
 },
 components: {
  ComfirmationDialog,
  TicketListDetail,
  SearchFilter,
  Loading,
  FilterIcon,
  SearchIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  BellIcon,
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  LockOpenIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TagIcon,
  UserCircleIconMini,
  TicketsHeader,
 },

 data() {
  return {
   account,
   isLoading: true,
   fullPage: false,
   editor: ClassicEditor,
   editorData: "",
   editorConfig: {
    fillEmptyBlocks: false,
    tabSpaces: 0,
    placeholder: "",
    plugins: [
     EssentialsPlugin,
     BoldPlugin,
     ItalicPlugin,
     LinkPlugin,
     ParagraphPlugin,
     ImageInsertPlugin,
     SimpleUploadAdapter,
     ImagePlugin,
     ImageResizePlugin,
     ImageStylePlugin,
     ImageToolbarPlugin,
     AutoImagePlugin,
     IndentPlugin,
     IndentBlockPlugin,
     ListStylePlugin,
    ],
    simpleUpload: {
     // The URL that the images are uploaded to.
     uploadUrl: `${this.$cookie.getCookie("API")}/api/v1/pictures`,

     // Enable the XMLHttpRequest.withCredentials property.
     withCredentials: false,

     // Headers sent along with the XMLHttpRequest to the upload server.
     headers: {
      "X-CSRF-TOKEN": "CSRF-Token",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    },
    toolbar: {
     items: [
      "heading",
      "bold",
      "italic",
      "|",
      "link",
      "|",
      "undo",
      "redo",
      "|",
      "indent",
      "outdent",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertImage",
     ],
    },
    indentBlock: {
     offset: 1,
     unit: "em",
    },
    language: "en",
    image: {
     toolbar: ["imageTextAlternative", "imageStyle:inline", "imageStyle:block", "imageStyle:side"],
    },
    licenseKey: "",
   },
   ticket: {},
   id: "",
   statusName: "",
   ticketUser: {},
   isFocus: false,
   showMessage: false,
   subMessageToShow: "",
   messageToShow: "",
   search: "",
   searchStatus: [],
   statusList: [],
   isListShow: true,
   userName: "",
   ticketReplies: [],
   count: 0,
   interval: undefined,
   userPermissions,
  };
 },
 methods: {
  getUserReply(id) {
   let name = "";
   for (let index = 0; index < this.ticketReplies.length; index++) {
    if (this.ticketReplies[index].id == id) {
     name =
      this.ticketReplies[index].user?.name && this.ticketReplies[index].origin !== "admin"
       ? this.ticketReplies[index].user?.name
       : "";
     return name;
    }
   }
  },

  async getTicket(account) {
   this.$emit("setLoading", true);
   const id = this.$route.params.id;
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.ticket = res.data;
     this.id = res.data.id;
     this.ticketReplies = res.data.replies;
     this.$emit("setLoading", false);
     this.$forceUpdate();
     if (res.data.assignee_id) {
      // this.getUser(res.data.assignee_id);
     }
    }
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
    if (error.response.status === 403 && error.response.statusText === "Forbidden") {
     this.$router.push("/unauthorized");
    }
   }
  },
  async getTicketReplies(id) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.ticketReplies = res.data.replies;
     this.isLoading = false;
     this.$forceUpdate();
    }
   } catch (error) {
    this.isLoading = false;
    this.errorHandling(error);
   }
  },
  async getStatus() {
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.statusList = res.data;
  },
  getTicketDetail(...args) {
   this.$router.push(`/ticket/${args[0]}`);
  },
  pushSearchStatus(status) {
   if (this.searchStatus.includes("closed")) {
    let index = this.searchStatus.indexOf("closed");
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
   if (!this.searchStatus.includes(status)) {
    this.searchStatus.push(status);
   } else {
    let index = this.searchStatus.indexOf(status);
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
  },
  showClosedTickets() {
   if (!this.searchStatus.includes("closed")) {
    this.searchStatus.length = 0;
    this.searchStatus.push("closed");
   } else {
    let index = this.searchStatus.indexOf("closed");
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
  },
  async reply(successMsg) {
   if (this.editorData === "") {
    //window.alert("Please write something before replying.");
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", "Please write something before replying.");
   }
   let str = String(this.editorData);
   // let noSpace = str.replaceAll("&nbsp;", "");
   let noSpace = str.split("&nbsp;").join("");
   if (localStorage.getItem("token"))
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/replies?customerAccount=${this.account}&ticketId=${
       this.id
      }&body=${noSpace}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      if (res) {
       // window.alert("Reply sent successfully.");
       // this.sendMessage(successMsg);
       // location.reload();
       window.scrollTo(0, document.body.scrollHeight);
       this.getTicketReplies(this.id);
       this.editorData = "";
      } else {
       window.alert("Something went wrong.");
      }
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
  },
  /*   async getUser(id) {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/users/${id}`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.userName = res.data.name;
   } catch (error) {
    this.errorHandling(error);
   }
  }, */
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    dateCreated.getHours() +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  addCount() {
   this.count = this.count + 1;
  },
  async openOrCloseTicket(open, id) {
   axios
    .put(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${this.account}&open=${
      open == 0 ? 1 : 0
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     let successMsg =
      open == 0 ? this.$t("ticketTable.reopenMsg") : this.$t("ticketTable.closeMsg");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
     open == 0 ? this.$router.push("/tickets") : this.$router.push("/closed-tickets");
    });
  },
  checkStatus(id) {
   if (this.statusList) {
    for (let index = 0; index < this.statusList.length; index++) {
     if (this.statusList[index].id == id) {
      return this.statusList[index].name;
     }
    }
   }
  },
  checkStatusColor(id) {
   if (this.statusList) {
    for (let index = 0; index < this.statusList.length; index++) {
     if (this.statusList[index].id == id) {
      return this.statusList[index].color;
     }
    }
   }
  },
  async getTickets() {
   if (localStorage.getItem("token")) {
    this.$emit("setLoading", true);
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/tickets?customerAccount=${account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     this.$emit(
      "tickesCount",
      this.filterOpenTickets(res.data).length,
      this.filterClosedTickets(res.data).length
     );
    } catch (error) {
     this.errorHandling(error);
    }
    this.$emit("setLoading", false);
   }
  },
  filterOpenTickets(data) {
   return data.filter((res) => {
    return res.open;
   });
  },
  filterClosedTickets(data) {
   return data.filter((res) => {
    return !res.open;
   });
  },
 },
 mounted() {
  this.getStatus();
  this.getTickets();
  this.getTicket(this.account);
  if (this.$route.path === "/closed-tickets") {
   this.searchStatus.push("closed");
  }
 },

 watch: {
  $route(to, from) {
   // Call a method to reload the ticket data here
   if (this.account && this.$route.params.id) this.getTicket(this.account);
  },
 },
 created() {
  // this.interval = setInterval(() => this.getTicketReplies(this.id), 1000);
 },
 computed: {
  ...mapGetters(["token"]),
 },
 beforeUnmount() {
  clearInterval(this.interval);
 },
};
</script>

<style scoped>
.bubbleWrapper {
 padding: 10px 5px;
 display: flex;
 justify-content: flex-end;
 flex-direction: column;
 align-self: flex-end;
 color: #fff;
}
.inlineContainer {
 display: inline-flex;
}
.inlineContainer.own {
 flex-direction: row-reverse;
}
.inlineIcon {
 width: 20px;
 object-fit: contain;
}
.internalNote {
 min-width: 60px;
 max-width: 700px;
 padding: 14px 18px;
 margin: 3px 8px;
 background-color: #22384b;
 border-radius: 10px 10px 10px 0;
 /* border: 1px solid #22384b; */
}
.ownBubble {
 min-width: 60px;
 max-width: 700px;
 padding: 14px 18px;
 margin: 3px 8px;
 background-color: #80cab0;
 border-radius: 10px 10px 0 10px;
 /* border: 1px solid #80cab0; */
}
.otherBubble {
 min-width: 60px;
 max-width: 700px;
 padding: 14px 18px;
 margin: 3px 8px;
 background-color: #6c8ea4;
 border-radius: 10px 10px 10px 0;
 /* border: 1px solid #6c8ea4; */
}
.own {
 align-self: flex-end;
}
.other {
 align-self: flex-start;
}
span.own,
span.other {
 font-size: 14px;
 color: grey;
}
.ticketBody :deep(img) {
 max-height: 500px;
 max-width: 80%;
}
</style>
